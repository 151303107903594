
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'index',
    props: {
      project: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        fields: [
          { key: 'project', label: '都市計畫名稱' },
          { key: 'industryIndex', label: '工業區編號' },
          { key: 'totalArea', label: '工業區總面積(㎡)<br>(A)' },
          { key: 'area', label: '管制面積(㎡)<br>(B)' },
          { key: 'applyArea', label: '可申請面積(㎡)<br>(C)=B-D' },
        ],
      }
    },
    methods: {},
  })
