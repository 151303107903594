
  import { defineComponent } from 'vue'
  import converter from 'number-to-chinese-words'

  export default defineComponent({
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    methods: {
      numberChange(index: number): string {
        return `（${converter.toWords(index)}）`
      },
    },
  })
