<template>
  <div :class="['container', 'main-width', 'license-container']">
    <div
      class="text-elite-blue fs-36 lh-28 fw-bold mb-5 mb-md-4 mb-sm-3 fs-28-sm"
    >
      <div class="mb-1">甲種乙種工業區總量管制</div>
      <div class="text-elite-blue fs-6 lh-base fw-normal">
        *本系統數據為預查參考使用，申請案件時請與本府都市計畫科確認(037-327220)。
      </div>
    </div>
    <div class="d-flex d-block-sm">
      <div
        class="d-flex align-items-center justify-content-between-sm mb-2 mb-md-0"
      >
        <div class="text-elite-blue fs-18 lh-28 me-3 me-0-sm">都市計畫</div>
        <select
          v-model="selectedPlan"
          class="form-select select-box py-3 ps-4 text-elite-blue fs-14"
          @change="changePlan"
        >
          <option
            v-for="plan in [{ name: '請選擇', id: '' }, ...plans]"
            :key="plan.id"
            :value="plan.id"
            :hidden="plan.id === ''"
            :disabled="plan.id === ''"
          >
            {{ plan.name }}
          </option>
        </select>
      </div>
      <div
        v-if="selectedPlan"
        class="d-flex align-items-center ms-5 ms-0-sm justify-content-between-sm"
      >
        <div class="d-inline text-elite-blue fs-18 lh-28 me-3 me-0-sm">
          工業區
        </div>
        <select
          class="form-select secend-select-box py-3 ps-4 text-elite-blue fs-14"
          v-model="selectedIndustry"
          @change="changeIndustry"
        >
          <option
            v-for="industry in [
              { industrial_district: '全部', id: '' },
              ...industryOptions,
            ]"
            :key="industry.id"
            :value="industry.id"
          >
            {{ industry.industrial_district }}
          </option>
        </select>
      </div>
    </div>
    <a-spin :indicator="indicator" :spinning="isLoading">
      <div class="mt-4" :class="{ 'mb-5 pb-5': !fixedHeight }">
        <striped-header-table
          v-if="selectedPlan"
          :project="plans.find((plan) => plan.id === selectedPlan).name"
          :items="industries"
        />
        <div v-if="selectedIndustry">
          <span class="text-elite-blue fs-16 lh-28">
            登錄現況 ：公共服務設施及公共事業設施 (面積單位：㎡)
          </span>
          <striped-table :items="industryDetail" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import { defineComponent, h } from 'vue'
  import { mapState } from 'vuex'
  import { LoadingOutlined } from '@ant-design/icons-vue'

  import { urbanPlanning } from '@/service'
  import StripedHeaderTable from '@/components/ContentTable/StripedTable/StripedHeaderTable/index.vue'
  import StripedTable from '@/components/ContentTable/StripedTable/index.vue'

  export default defineComponent({
    name: 'TotalAmountControl',
    created() {
      urbanPlanning.get().then(({ data }) => {
        this.plans = data
      })
    },
    components: {
      StripedHeaderTable,
      StripedTable,
    },
    computed: {
      ...mapState('project', ['projects', 'totalProjects']),
      industries() {
        const { selectedIndustry, industryOptions } = this

        return selectedIndustry
          ? industryOptions.filter((item) => item.id === selectedIndustry)
          : industryOptions
      },
    },
    data() {
      const indicator = h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      })
      return {
        selectedPlan: '',
        selectedIndustry: '',
        plans: [],
        industryOptions: [],
        industryDetail: [],
        isLoading: false,
        indicator,
      }
    },
    methods: {
      changePlan() {
        this.selectedIndustry = ''
        this.industryOptions = []
        this.isLoading = true
        urbanPlanning
          .getIndustrialDistricts(this.selectedPlan)
          .then(({ data }) => {
            this.industryOptions = data
          })
          .finally(() => (this.isLoading = false))
        // if (event) {
        //   this.fixedHeight = !this.fixedHeight
        // }
        // if (this.selectedPlan === 0) {
        //   this.projectDatas = this.totalProjects
        // } else {
        //   const projectTitle: string = this.projectOptions.filter(
        //     (item) => item.value === this.selectedPlan
        //   )[0].title
        //   this.projectDatas = this.projects.filter(
        //     (item: { project: string }) => item.project === projectTitle
        //   )
        // }
        // this.selectedIndustry = 0
        // this.industryOptions = [
        //   { title: '全部', value: 0 },
        //   ...this.projectDatas.map(({ industryIndex }, index) => ({
        //     title: industryIndex,
        //     value: index + 1,
        //   })),
        // ]
      },
      changeIndustry() {
        urbanPlanning
          .getDetail(this.selectedPlan, this.selectedIndustry)
          .then(({ data }) => (this.industryDetail = data))
        // const industryTitle: string | undefined = this.industryOptions.find(
        //   (item) => item.value === this.selectedIndustry
        // )?.title
        // this.projectDatas = this.projects.filter(
        //   (item: { industryIndex: string; project: string }) =>
        //     item.industryIndex === industryTitle ||
        //     (this.selectedIndustry === 0 &&
        //       item.project === this.projectOptions[this.selectedPlan].title)
        // )
      },
    },
  })
</script>

<style lang="scss" scoped>
  .select-box {
    width: 307px;
    border-radius: 8px;
  }

  .secend-select-box {
    width: 220px;
    border-radius: 8px;
  }
</style>
